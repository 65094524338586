<!-- eslint-disable array-callback-return -->
<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col
          md="12"
          xl="9"
        >
          <b-row class="match-height">
            <!-- supplier -->
            <b-col md="6">
              <b-card :title="$t('Supplier')">
                <validation-provider
                  #default="{ errors }"
                  name="Supplier"
                  rules="required"
                >
                  <v-select
                    v-model="model.supplier_id"
                    :options="supplierOptions"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="w-100 mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Add New Supplier') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="loadSupplier"
                      >
                        <feather-icon
                          icon="RefreshCwIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div>51 Yên Thế, Phường 2, Tân Bình, TP. Hồ Chí Minh</div>
                <div class="d-flex justify-content-between mt-1">
                  <span>Còn nợ: <strong>3.000.000 đ</strong></span>
                  <router-link to="#">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <!-- warehouse -->
            <b-col md="6">
              <b-card :title="$t('Warehouse')">
                <validation-provider
                  #default="{ errors }"
                  name="Warehouse"
                  rules="required"
                >
                  <v-select
                    v-model="model.warehouse_id"
                    :options="warehouseOptions"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Add New Warehouse') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="loadSupplier"
                      >
                        <feather-icon
                          icon="RefreshCwIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div>51 Yên Thế, Phường 2, Tân Bình, TP. Hồ Chí Minh</div>
                <div class="d-flex justify-content-between mt-1">
                  <span>0982879920</span>
                  <router-link to="#">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                :title="$t('Product')"
                class="mih-100"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Button on right" />
                  <b-input-group-append is-text>
                    <span
                      class="cursor-pointer"
                      @click="modalShow = !modalShow"
                    >
                      {{ $t('Search') }}
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <!-- <div class="mih-100 mt-1" style="overflow-x: auto;">
                  <div>
                    <b-row class="bg-gray no-gutters d-flex text-nowrap">
                      <b-col cols="3">Sản phẩm</b-col>
                      <b-col cols="1" class="text-nowrap" style="min-width: 150px">Tồn kho</b-col>
                      <b-col cols="2" class="text-right" style="min-width: 150px">SL nhập</b-col>
                      <b-col cols="2" class="text-right" style="min-width: 150px">Đơn giá</b-col>
                      <b-col cols="2" class="text-right" style="min-width: 150px">Giá giảm</b-col>
                      <b-col cols="2" class="text-center" style="min-width: 150px">Thành tiền</b-col>
                    </b-row>
                    <div>
                      <ul>
                        <li
                          v-for="(item, index) in items" :key="index"
                          class="mt-1"
                        >
                          <div>
                            <label :for="`parent-${item.id}`" class="d-flex align-items-center hover-bg-gray">
                              <div class="d-flex align-items-center pl-1">
                                <b-avatar square variant="light-dark" :src="item.avatar" />
                                <span class="pl-1">{{ item.name }}</span>
                              </div>
                            </label>
                          </div>
                          <ul>
                            <li
                              v-for="(val, idx) in item.attribute" :key="idx"
                              class="mt-1"
                            >
                              <b-row class="hover-bg-gray">
                                <b-col cols="3" class="d-flex align-items-center">
                                  <label :for="`${val.id}`">
                                    <div class="d-flex pl-4">
                                      <div class="pl-1">
                                        <span class="d-block">{{ val.name }}</span>
                                        <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                                      </div>
                                    </div>
                                  </label>
                                </b-col>
                                <b-col cols="1" class="d-flex align-items-center">
                                  <span>300</span>
                                </b-col>
                                <b-col
                                  cols="2"
                                  class="d-flex align-items-center justify-content-end p-0"
                                >
                                  <b-form-input class="w-75" type="number" size="sm" placeholder="Nhập số lượng" />
                                </b-col>
                                <b-col
                                  cols="2"
                                  class="d-flex align-items-center justify-content-end p-0"
                                >
                                  <b-form-input class="w-75" type="number" size="sm" placeholder="Nhập đơn giá" />
                                </b-col>
                                <b-col
                                  cols="2"
                                  class="d-flex align-items-center justify-content-end p-0"
                                >
                                  <b-form-input class="w-75" type="number" size="sm" placeholder="Giá giảm" />
                                </b-col>
                                <b-col
                                  cols="2"
                                  class="d-flex justify-content-between text-right align-items-center"
                                >
                                  <span>1.000.000 đ</span>
                                  <feather-icon class="cursor-pointer" size="18" icon="XIcon" />
                                </b-col>
                              </b-row>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
                <div class="mt-1">
                  <vue-good-table
                    :columns="columnsDone"
                    :rows="rowsDone"
                    class="custom-good-table-row-head"
                    style-class="vgt-table"
                    :group-options="{
                      enabled: true,
                      headerPosition: 'top'
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }"
                    @on-selected-rows-change="selectionChanged"
                  >
                    <template
                      slot="table-header-row"
                      slot-scope="props"
                    >
                      <b-avatar
                        :key="props.row.img"
                        :src="props.row.img"
                        variant="light-info"
                        rounded
                        class="p-avatar"
                      />
                      <span class="my-fancy-class">
                        {{ props.row.label }}
                      </span>
                    </template>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <div
                        v-if="props.column.field === 'name'"
                        class="avatar-name mx-auto"
                      >
                        <div class="name-child">
                          {{ props.row.name }}
                        </div>
                        <div class="sku">
                          <strong>SKU:&nbsp;</strong>{{ props.row.sku }}
                        </div>
                      </div>
                      <div
                        v-else-if="props.column.field === 'stock'"
                        class="count-w mx-auto"
                      >
                        {{ props.row.stock }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'quantity'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.quantity" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'price'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.price" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'discount'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.discount" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'total'"
                        class="text-end"
                      >
                        {{ props.row.total }} đ
                      </div>
                      <div
                        v-else-if="props.column.field === 'close'"
                        class="d-flex text-center"
                      >
                        <b-button
                          variant="flat-danger"
                          class="text-danger btn-icon rounded-circle"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </template>
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap ">
                            Showing 1 to
                          </span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10','30','100']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="12"
          xl="3"
        >
          <div class="position-sticky top">
            <b-card title="Nhân viên xử lý">
              <validation-provider
                #default="{ errors }"
                name="Supplier"
                rules="required"
              >
                <v-select
                  v-model="model.supplier_id"
                  :options="supplierOptions"
                  label="name"
                  input-id="invoice-data-client"
                  :clearable="false"
                  class="w-100 mb-1"
                >
                  <template #list-header>
                    <a
                      class="add-new-client-header d-flex align-items-center my-50"
                      tag="li"
                      href="/supplier/add"
                      target="_blank"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                      />
                      <span class="align-middle ml-50">{{ $t('Add New Supplier') }}</span>
                    </a>
                    <li
                      class="add-new-client-header d-flex align-items-center my-50"
                      @lick="loadSupplier"
                    >
                      <feather-icon
                        icon="RefreshCwIcon"
                        size="16"
                      />
                      <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                    </li>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                :label="$t('Mã tham chiếu') + `:`"
                label-cols-md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Shelf code"
                  rules="required"
                >
                  <b-form-input
                    v-model="model.code"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Shelf code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-card>
            <b-card title="Giá trị nhập">
              <div class="d-flex justify-content-between">
                <span>Tổng số lượng nhập</span>
                <span>0</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>Tổng tiền hàng</span>
                <span>0 đ</span>
              </div>
              <div class="d-flex justify-content-between">
                <strong>Tổng giá trị hàng</strong>
                <span>0 đ</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <strong class="text-primary">Tiền trả NCC</strong>
                <span>0 đ</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <strong>Còn nợ</strong>
                <span>0 đ</span>
              </div>
              <div class="text-center mt-2 w-100">
                <b-button
                  variant="primary"
                  type="submit"
                  class="w-100"
                  @click.prevent="validationForm"
                >
                  {{ $t('Stock Input') }}
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- modal add selected -->
    <b-modal
      id="modal-list"
      v-model="modalShow"
      size="lg"
      scrollable
      title="Tìm kiếm sản phẩm"
      no-stacking
    >
      <div class="mih-100">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Button on right" />
          <b-input-group-append is-text>
            <span class="cursor-pointer">
              {{ $t('Search') }}
            </span>
          </b-input-group-append>
        </b-input-group>
        <div class="mt-1">
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >Số tồn kho</b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in items"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray cursor-pointer"
                  >
                    <b-form-checkbox
                      :id="`parent-${item.id}`"
                      v-model="item.selected"
                      :indeterminate="item.indeterminate"
                      @change="handleSelectAll(item)"
                    />
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.attribute"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray cursor-pointer"
                    >
                      <div class="d-flex pl-4">
                        <b-form-checkbox
                          :id="`${val.id}`"
                          v-model="val.selected"
                          @change="handleSelectItem(val)"
                        />
                        <div class="pl-1">
                          <span class="d-block">{{ val.name }}</span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">{{ val.stock }} tồn kho</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
            :class="selectedArr.length ? 'text-primary cursor-pointer' : ''"
            @click="modalShowItem = !modalShowItem"
          >
            {{ selectedArr.length }} sản phẩm đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="show = false"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShow = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- modal show item be selected -->
    <b-modal
      id="modal-show-item"
      v-model="modalShowItem"
      size="lg"
      scrollable
      title="Sản phẩm đã chọn"
      no-stacking
    >
      <div class="mih-100">
        <div>
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >Số tồn kho</b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in items"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray"
                  >
                    <!-- <b-form-checkbox :id="`parent-${item.id}`" v-model="item.selected" :indeterminate="item.indeterminate" @change="handleSelectAll(item)"/> -->
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                    <div class="pl-2">
                      <feather-icon
                        class="cursor-pointer"
                        size="18"
                        icon="XIcon"
                      />
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.attribute"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray"
                    >
                      <div class="d-flex pl-4">
                        <!-- <b-form-checkbox :id="`${val.id}`" v-model="val.selected" @change="handleSelectItem(val)" /> -->
                        <div class="pl-1">
                          <span class="d-block">{{ val.name }}</span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">
                        <feather-icon
                          class="cursor-pointer"
                          size="18"
                          icon="XIcon"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
          >
            {{ selectedArr.length }} sản phẩm đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="show = false"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShow = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
// eslint-disable vue/max-attributes-per-line
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  // BFormCheckbox,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  // BCardHeader,
  BAvatar,
  BPagination,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
// import Treeselect from '@riophae/vue-treeselect'
// import vrcode from '@ispa.io/vrcode'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    BCard,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    // BCardHeader,
    BAvatar,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    // Treeselect,
    // vrcode,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      avatarText,
      required,
      supplierOptions: [
        {
          id: 1,
          name: 'Nhà Cung cấp 01',
        },
      ],
      warehouseOptions: [],
      model: {
        name: null,
        qrcode: null,
        code: null,
        place: null,
        tag: null,
        quantity: null,
        position: null,
        is_active: true,
      },
      pageLength: 10,
      // setting col row list be selected
      columns: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Tồn kho',
          field: 'inventory',
          tdClass: 'text-center',
        },
        // {
        //   label: 'Tồn thực thế',
        //   field: 'actualstock',
        //   tdClass: 'text-center',
        // },
        // {
        //   label: 'Số lượng',
        //   field: 'count',
        //   tdClass: 'text-center',
        // },
        // {
        //   label: 'Giá trị lệch',
        //   field: 'value',
        //   tdClass: 'text-center',
        // },
        // {
        //   label: 'Xóa',
        //   field: 'close',
        //   tdClass: 'text-center',
        // },
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      // setting col row list select
      rowsDone: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 600,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 600,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 600,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 600,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 600,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 60,
              quantity: 0,
              price: 0,
              discout: 0,
              total: 0,
              count: 10,
              value: 0,
            },
          ],
        },
      ],
      columnsDone: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tồn kho',
          field: 'stock',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'SL Nhập',
          field: 'quantity',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Đơn giá',
          field: 'price',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Giá giảm',
          field: 'discount',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Thành tiền',
          field: 'total',
          tdClass: 'text-right miw-150',
          thClass: 'text-right',
          sortable: false,
        },
        {
          label: '',
          field: 'close',
          tdClass: 'text-end',
          sortable: false,
        },
      ],
      // setting col row list selected
      rowsAdded: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      columnsAdded: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tool',
          field: 'close',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      modalShow: false,
      modalShowItem: false,
      items: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 1,
              id: 11,
              name: 'Xanh/ S/ Thun',
              stock: 5,
              sku: 'A0038687',
              barcode: 'A0038687',
              selected: false,
            },
            {
              parent_id: 1,
              id: 12,
              name: 'Đen/ S/ Thun',
              stock: 5,
              sku: 'A00386877',
              barcode: 'A00386877',
              selected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Áo Khoác cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 2,
              id: 21,
              name: 'Đen/ XL/ Kaki',
              stock: 1,
              sku: 'A00386878',
              barcode: 'A00386878',
              selected: false,
            },
          ],
        },
      ],
      pSelected: [],
      cSelected: [],
      // list tạm thời đã chọn
      selectedArr: [],
    }
  },
  watch: {
    cSelected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  created() {},
  methods: {
    handleSelectItem(val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id === val.parent_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].attribute.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d > 0) this.items[parent].indeterminate = true
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d === this.items[parent].attribute.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
    },
    handleSelectAll(val) {
      const index = this.items.find(x => x.id === val.id)
      // eslint-disable-next-line array-callback-return
      index.attribute.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.selected = val.selected
      })
      this.saveTempArr()
    },
    saveTempArr(item, option = {}) {
      // eslint-disable-next-line array-callback-return
      if (option.all) {
        this.selectedArr.map(x => x.id.include(item.id))
      }
    },
    selectionChanged(e) {
      this.selectedArr = e.selectedRows
    },
    loadSupplier() {},
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              phone: this.model.phone,
              address: this.model.address,
              translation: JSON.stringify(translation),
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/supplier`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/warehouse-list/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;
    cursor: pointer;

    &:hover {
        background-color: rgba($success, 0.12);
    }
}
.custom-good-table-row-head {
    .custom-select {
        width: 100%;
    }
    .b-avatar {
        margin-right: 8px;
    }
    table.vgt-table {
        font-size: 14px !important;
    }
    thead th {
        vertical-align: middle;
        text-align: center;
        // padding-right: 0.75em;
    }
    tbody th.vgt-checkbox-col {
        padding: 0 0.75em 0 2.75em;
        border-right: none;
    }
    td {
        vertical-align: middle !important;
    }
    .p-avatar {
        margin-left: 16px;
    }
    .avatar-name {
        min-width: 200px !important;
        padding-left: 4.25em;

        .name-child {
            color: #333;
            font-weight: 500;
        }
    }
    .count-w {
        max-width: 100px;
    }
    .miw-150 {
        min-width: 150px;
    }
    .vgt-table th.vgt-row-header {
        // border-top: 3px solid #ebe9f1;
        border-bottom: none;
        font-size: 14px;
        thead th {
            vertical-align: middle;
            text-align: center;
            padding-right: 0.75em;
            padding-left: 0;
            white-space: nowrap;
        }
        td {
            vertical-align: middle;
        }

        .custom-th-class {
            text-align: end;
        }
    }
}
#modal-show-item {
    table.vgt-table {
        thead {
            display: none;
        }
        tbody td.text-left {
            padding-left: 5.25em;
            border-bottom: none;
        }
        tbody td.text-center {
            border-bottom: none;
        }
    }
}
</style>
<style lang="scss" scoped>
ul {
    padding: 0;
    margin-top: 1rem;
}
ul li {
    list-style: none;
}
.bg-gray {
    background: #f6f6f6;
    padding: 12px 0;
}
.hover-bg-gray {
    padding: 0.5rem 0;
}
.hover-bg-gray:hover {
    padding: 0.5rem 0;
    background: #f6f6f6;
}
label {
    font-size: 14px;
}
.mih-100 {
    min-height: 350px;
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
